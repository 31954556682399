const messages = {
  en: {
    "content.fetching": "Fetching ...",
    "content.no-data": "No Content!",
    "content.switch": "Go back to question.",
    "nav.search": "Search Resources",
    "nav.pages": "Pages",
    "handbook.view": "View",
    "handbook.no-pages": "No Pages",
    "handbook.pages": "Pages",
    "handbook.audience": "Audience",
    "handbook.outcomes": "Outcomes",
    "handbook.phases": "Phases",
    "handbook.overview": "Overview",
    "handbook.share": "Share on Social Media",
    "page.view": "View",
    "page.name": "Name",
    "page.content": "Content",
    "footer.dial": "The Digital Impact Alliance",
    "footer.dial-message": " is a partnership amongst some of the world’s most active digital development champions. We are grateful for the support of our Founding Partners.",
    "footer.gitlab": "View this project on Gitlab",
    "footer.social-media": "Follow DIAL on social media",
    "footer.icons": "Icons provided unmodified by",
    "footer.font-awesome": "Font Awesome",
    "footer.font-sdg": "The Global Goals for Sustainable Development",
    "header.find-handbook": "Find a Handbook",
    "header.product-catalog": "Product Catalog",
    "header.resources-portal": "Resources Portal",
    "header.export-pdf": "Export PDF",
    "header.sign-in": "Sign In",
    "header.sign-up": "Sign Up",
    "header.english": "English",
    "header.french": "French",
    "header.deutsch": "Deutsch",
    "navigation.next": "Next Page",
    "navigation.previous": "Previous Page",
    "navigation.return": "Stop Run",
    "export.header": "Export",
    "export.execute": "Export Pages",
    "export.exporting": "Preparing Export..."
  },
  de: {
    "nav.search": "Suchen Resources",
    "nav.pages": "Seiten",
    "handbook.view": "Ansehen",
    "handbook.no-pages": "Keine Seiten",
    "handbook.pages": "Seiten",
    "handbook.audience": "Audienz",
    "handbook.outcomes": "Resultat",
    "handbook.phases": "Phase",
    "handbook.overview": "Überblick",
    "page.view": "Ansehen",
    "page.name": "Name",
    "page.content": "Inhalt",
    "footer.dial": "The Digital Impact Alliance",
    "footer.dial-message": " ist eine Partnerschaft zwischen einigen der aktivsten digitalen Entwicklungs-Champions der Welt. Wir sind dankbar für die Unterstützung unserer Gründungspartner.",
    "footer.gitlab": "Sehen sie sich dieses projekt auf Gitlab an",
    "footer.social-media": "Folgen sie DIAL in den sozialen medien",
    "footer.icons": "Icons unmodifizierten bereitgestellt durch",
    "footer.font-awesome": "Font Awesome",
    "footer.font-sdg": "The Global Goals for Sustainable Development",
    "header.find-handbook": "Finde ein Playbook",
    "header.product-catalog": "Product Catalog",
    "header.resources-portal": "Resources Portal",
    "header.export-pdf": "Export als PDF",
    "header.sign-in": "Sign In",
    "header.sign-up": "Sign Up",
    "header.english": "English",
    "header.french": "French",
    "header.deutsch": "Deutsch"
  },
  fr: {
    "nav.search": "Recherche Resources",
    "nav.pages": "Pages",
    "handbook.view": "Visiter",
    "handbook.no-pages": "Aucune page",
    "handbook.pages": "Pages",
    "handbook.audience": "Auditoire",
    "handbook.outcomes": "Résultats",
    "handbook.phases": "Phase",
    "handbook.overview": "Aperçus",
    "page.view": "Visiter",
    "page.name": "Nom",
    "page.content": "Contenu",
    "footer.dial": "The Digital Impact Alliance",
    "footer.dial-message": " est un partenariat entre certains des champions du développement numérique les plus actifs au monde. Nous sommes reconnaissants du soutien de nos partenaires fondateurs.",
    "footer.gitlab": "Voir ce projet sur Gitlab",
    "footer.social-media": "Suivez DIAL sur les réseaux sociaux",
    "footer.icons": "Icônes fournies non modifiées par",
    "footer.font-awesome": "Font Awesome",
    "footer.font-sdg": "The Global Goals for Sustainable Development",
    "header.find-handbook": "Trouver un Playbook",
    "header.product-catalog": "Product Catalog",
    "header.resources-portal": "Resources Portal",
    "header.export-pdf": "Exporter en PDF",
    "header.sign-in": "Sign In",
    "header.sign-up": "Sign Up",
    "header.english": "English",
    "header.french": "French",
    "header.deutsch": "Deutsch"
  }
}

export default messages;