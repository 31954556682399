// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-content {
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 250px; }

.handbook-logo-frame {
  margin-right: 0.5rem;
  background-color: #dddddd; }
`, "",{"version":3,"sources":["webpack://./src/styles/handbookcard.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB,EAAA;;AAGnB;EACE,oBAAoB;EACpB,yBAAyB,EAAA","sourcesContent":[".card-content {\n  overflow-x: hidden;\n  overflow-y: hidden;\n  max-height: 250px; \n}\n\n.handbook-logo-frame {\n  margin-right: 0.5rem;\n  background-color: #dddddd;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
