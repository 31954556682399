import React, { useState, createContext } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import { Row, Col} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';

import withRouter from '../lib/withRouter'
import HandbookNav from '../components/HandbookNav'
import TopNav from '../components/TopNav'
import HandbookContent from '../components/HandbookContent'
import { HandbookContext, SearchContext } from '../HandbookContext'
import HandbookSearch from '../components/HandbookSearch'
import HandbookExport from '../components/HandbookExport'

import '../styles/handbook.scss';

const HANDBOOK_QUERY = gql`
query GetHandbook($slug: String!) {
  handbook(slug: $slug) {
    id
    name
    phases
    imageFile
    slug
    pdfUrl
    handbookDescriptions {
      locale
      overview
      audience
      outcomes
      cover
    }
    handbookPages { 
      id
      name
      phase
      slug
      handbookQuestion {
        id
        questionText
        handbookAnswers {
          id
          answerText
          action
        }
      }
      childPages { 
        id
        name
        phase
        slug
        handbookQuestion {
          id
          questionText
          handbookAnswers {
            id
            answerText
            action
          }
        }
        childPages { 
          id
          name
          phase
          slug
          handbookQuestion {
            id
            questionText
            handbookAnswers {
              id
              answerText
              action
            }
          }
        }
      }
    }
  }
}
`

const sidePanelDisplayed = function(searchTerm, exportFlag) {
  return searchTerm || exportFlag;
}

const Handbook = (props) => {
  const { slug } = props.router?.params;
  const [searchTerm, setSearchTerm] = useState('');
  const [contextData, setContextData] = useState({
    currentNav: undefined,
    currentAnswer: undefined,
    exportFlag: false
  });
  const value = { contextData, setContextData };
  return ( slug &&
    <Query query={HANDBOOK_QUERY}
      variables={{ slug: slug }}>
      {({ loading, error, data }) => {
        if (loading) return <div><FormattedMessage id="content.fetching" /></div>
        if (error) { console.log(error); return <div><FormattedMessage id="content.no-data" /></div> }
        const handbook = data.handbook
        return (
          <HandbookContext.Provider value={value}>
            <SearchContext.Provider value={{searchTerm, setSearchTerm}}>
              <TopNav {...handbook} />
              <div id="content" className="container-fluid">
                <Row>
                  <Col md={3} className="px-0 independent-scroller">
                    <HandbookNav {...handbook} />
                  </Col>
                  {
                    sidePanelDisplayed(searchTerm, contextData.exportFlag) ?
                      <>
                        <Col md={6} className="px-0 mt-0 independent-scroller">
                          <HandbookContent {...handbook}/>
                        </Col>
                        <Col md={3} className="px-0 mt-0 independent-scroller">
                          { searchTerm && <HandbookSearch {...handbook}/> }
                          { contextData.exportFlag && <HandbookExport {...handbook}/> }
                        </Col>
                      </>
                    :
                      <Col md={9} className="px-0 mt-0 independent-scroller">
                        <HandbookContent {...handbook}/>
                      </Col>
                  }
                </Row>
              </div>
            </SearchContext.Provider>
          </HandbookContext.Provider>
        )
      }}
    </Query>
  )
}
export default withRouter(Handbook);

