import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Card from 'react-bootstrap/Card'

import { FormattedMessage } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'

import { EmailShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import { EmailIcon, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share'

import config from '../config';
import '../styles/handbookcard.scss';

const HandbookCard = ({ handbook }) => (
  <div className='col-12 col-md-3 mt-2 p-3'>
    <Card className='index-card'>
      <Card.Header className='bg-secondary text-white truncate-parent'>
        <Link to={`/resources/${handbook.slug}`}>
          {handbook.name}
        </Link>
      </Card.Header>
      <div className='handbook-logo-frame ml-2 mt-2 border-bottom'>
        <Link to={`/resources/${handbook.slug}`}>
          <img src='/logo-dial.png' alt='DIAL logo' height='50px' className='mr-2 d-inline' />
          <img src={handbook.imageFile.replaceAll(config.handbook_image_url, config.handbooks_server + '/' + config.handbook_image_url)} className='project-logo align-middle d-inline' alt='Handbook' />
        </Link>
      </div>
      <div className='p-2'>
        <FormattedMessage id='handbook.overview' />:
      </div>
      <div className='p-2 overflow-hidden'>
        { handbook.handbookDescriptions[0].cover
          ? ReactHtmlParser(handbook.handbookDescriptions[0].cover.replaceAll(config.handbook_image_url, config.handbooks_server + '/' + config.handbook_image_url))
          : ReactHtmlParser(handbook.handbookDescriptions[0].overview.replaceAll(config.handbook_image_url, config.handbooks_server + '/' + config.handbook_image_url)) 
        }
      </div>
      <Card.Footer className='footer-padding'>
        <div className='footer-row card-content text-muted'>
          <FormattedMessage id='handbook.share' />:
        </div>
        <div className='footer-row card-content'>
          <TwitterShareButton url={config.handbooks_server+`/resources/${handbook.slug}`}>
            <TwitterIcon size={32} className='p-1' round={true} />
          </TwitterShareButton>
          <FacebookShareButton url={config.handbooks_server+`/resources/${handbook.slug}`}>
            <FacebookIcon size={32} className='p-1' round={true} />
          </FacebookShareButton>
          <LinkedinShareButton url={config.handbooks_server+`/resources/${handbook.slug}`}>
            <LinkedinIcon size={32} className='p-1' round={true} />
          </LinkedinShareButton>
          <EmailShareButton url={config.handbooks_server+`/resources/${handbook.slug}`}>
            <EmailIcon size={32} className='p-1' round={true} />
          </EmailShareButton>
        </div>
      </Card.Footer>
    </Card>
  </div>
  )

HandbookCard.propTypes = {
  handbook: PropTypes.object.isRequired
};

export default HandbookCard;
