import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { CSSTransition } from 'react-transition-group'

import '../styles/reveal.scss'

const RevealViewer = (props) => {
  const transformNode = (node) => {
    if (node.name === 'a' && node.attribs) {
      if (node.attribs['data-type'] && node.attribs['data-type'] === 'page-link') {
        const pageId = node.attribs['data-page']
        return (
          <a
            href={`link-page-${pageId}`}
            key={`key-page-${pageId}`}
            onClick={(e) => {
              e.preventDefault()
              props.contentLinkHandler(pageId)
            }}
          >
            {node.children.map(child => convertNodeToElement(child))}
          </a>
        )
      }
      if (node.attribs.href && node.attribs.href.indexOf('slug::')) {
        const hrefElements = node.attribs.href.split('slug::')
        const pageSlug = hrefElements[hrefElements.length - 1]
        const { pageId } = props.findPageIdWithSlug(String(pageSlug).replace(/^\s+|\s+$/g, ''))
        if (pageId) {
          return (
            <a
              href={`link-page-${pageId}`}
              key={`key-page-${pageId}`}
              onClick={(e) => {
                e.preventDefault()
                props.contentLinkHandler(pageId)
              }}
            >
              {node.children.map(child => convertNodeToElement(child))}
            </a>
          )
        }
      }
    }
  }

  const toggleContent = () => {
    setTriggerRevealed(!triggerRevealed)
    setContentRevealed(!contentRevealed)
  }

  const [contentRevealed, setContentRevealed] = useState(false)
  const [triggerRevealed, setTriggerRevealed] = useState(true)
  const { revealContent, triggerContent } = props

  return (
    <>
      <CSSTransition in={contentRevealed} timeout={500} classNames='reveal'>
        <>
          {
            contentRevealed &&
              <div
                className='reveal-content'
                onClick={() => toggleContent()}
              >
                {ReactHtmlParser(revealContent, { transform: transformNode })}
              </div>
          }
        </>
      </CSSTransition>
      <CSSTransition in={triggerRevealed} timeout={500} classNames='reveal'>
        <>
          {
            triggerRevealed &&
              <div
                className='reveal-trigger'
                onClick={() => toggleContent()}
              >
                {ReactHtmlParser(triggerContent, { transform: transformNode })}
              </div>
          }
        </>
      </CSSTransition>
    </>
  )
}

RevealViewer.propTypes = {
  revealContent: PropTypes.string.isRequired,
  triggerContent: PropTypes.string.isRequired,
  triggerEvent: PropTypes.string
}

export default RevealViewer
