import React, { useState } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import { Row, Col} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';

import { HandbookContext, SearchContext } from '../HandbookContext'
import HandbookContent from '../components/HandbookContent'
import HandbookExport from '../components/HandbookExport'
import HandbookNav from '../components/HandbookNav'
import HandbookSearch from '../components/HandbookSearch'
import PlayNav from '../components/PlayNav'
import TopNav from '../components/TopNav'

const HANDBOOK_QUERY = gql`
  query GetHandbook($slug: String!) {
    handbook(slug: $slug) {
      id
      name
      phases
      imageFile
      slug
      handbookDescriptions {
        locale
        overview
        audience
        outcomes
      }
      handbookPages { 
        id
        name
        phase
        slug
        handbookQuestion {
          id
          questionText
          handbookAnswers {
            id
            answerText
            action
          }
        }
        childPages { 
          id
          name
          phase
          slug
          handbookQuestion {
            id
            questionText
            handbookAnswers {
              id
              answerText
              action
            }
          }
          childPages { 
            id
            name
            phase
            slug
            handbookQuestion {
              id
              questionText
              handbookAnswers {
                id
                answerText
                action
              }
            }
          }
        }
      }
    }
  }
`

const sidePanelDisplayed = function(searchTerm, exportFlag) {
  return !!searchTerm || !!exportFlag;
}

const HandbookPlay = (props) => {
  const navigationQueue = [];
  const childToParent = {};
  const grandChildToChild = {};
  const { slug, startingId } = props.match.params;

  const [searchTerm, setSearchTerm] = useState('');
  const [contextData, setContextData] = useState({
    currentNav: startingId,
    currentAnswer: undefined,
    exportFlag: false,
    playing: true,
    childKey: undefined,
    parentKey: undefined,
  });

  const goToNextPage = function() {
    navigateForward(true);
  }

  const goToPreviousPage = function() {
    navigateForward(false);
  }

  const accordionStates = function(currentNav) {
    let stateChanges = {};
    if (currentNav) {
      /*
        Complicated logic to find which accordion we need to expand:
        * Decide if the current nav is a child:
          * Check child - parent map.
          * Check grandchild - child map.
        * If we find a child in one of the above mapping, then mark it as need to be expanded.
        * Set the parent of the child tbe expanded as well.
        * If no child is found:
          * Check if a parent have a child.
          * Mark that parent as need to be expanded. 
       */
      const childKeys = Object.keys(childToParent);
      const moreChildKeys = Object.values(grandChildToChild);
      const grandChildKeys = Object.keys(grandChildToChild);
      let childKeyIndex = childKeys.indexOf(currentNav);
      if (childKeyIndex >= 0) {
        stateChanges = { ...stateChanges, childKey: childKeys[childKeyIndex] };
      } else {
        childKeyIndex = moreChildKeys.indexOf(currentNav);
        if (childKeyIndex >= 0) {
          stateChanges = { ...stateChanges, childKey: moreChildKeys[childKeyIndex] };
        } else {
          const grandChildIndex = grandChildKeys.indexOf(currentNav);
          if (grandChildIndex >= 0) {
            stateChanges = { ...stateChanges, childKey: grandChildToChild[grandChildKeys[grandChildIndex]] };
          }
        }
      }

      if (stateChanges.childKey) {
        stateChanges = { ...stateChanges, parentKey: childToParent[stateChanges.childKey] };
      } else {
        const parentKeyIndex = Object.values(childToParent).indexOf(currentNav);
        if (parentKeyIndex >= 0) {
          stateChanges = { ...stateChanges, parentKey: Object.values(childToParent)[parentKeyIndex] };
        }
      }
    }
    return stateChanges;
  }

  const navigateForward = function(forward) {
    let stateChanges = {};
    const index = navigationQueue.indexOf(contextData.currentNav);
    const currentNav = navigationQueue[forward ? index + 1 : index - 1];
    stateChanges = { ...stateChanges, currentNav: currentNav,  ...accordionStates(currentNav)};
    setContextData({ ...contextData, ...stateChanges });
  }

  const buildnavigationQueue = function(handbook) {
    if (navigationQueue.length > 0)
      return;
  
    handbook.handbookPages.map(page => {
      navigationQueue.push(page.id);
  
      if (page.childPages) {
        page.childPages.map(childPage => {
          childToParent[childPage.id] = page.id;
          navigationQueue.push(childPage.id);
  
          if (childPage.childPages) {
            childPage.childPages.map  (grandChildPage => {
              grandChildToChild[grandChildPage.id] = childPage.id;
              navigationQueue.push(grandChildPage.id);
              return grandChildPage;
            });
          }
  
          return childPage;
        });
      }
  
      return page;
    });
  }

  return (
    <Query query={HANDBOOK_QUERY}
      variables={{ slug: slug }}>
      {({ loading, error, data }) => {
        if (loading) return <div><FormattedMessage id="content.fetching" /></div>
        if (error) { console.log(error); return <div><FormattedMessage id="content.no-data" /></div> }
        const handbook = data.handbook;
        buildnavigationQueue(handbook);

        const values = {
          contextData: { ...contextData, ...accordionStates(contextData.currentNav) },
          setContextData: setContextData
        };
        return (
          <HandbookContext.Provider value={ values }>
            <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
              <TopNav {...handbook} />
              <div id="content" className="container-fluid mt-0">
                <Row>
                  <Col md={3} className="px-0 independent-scroller">
                    <HandbookNav {...handbook} />
                  </Col>
                  {
                    sidePanelDisplayed(searchTerm, contextData.exportFlag) ?
                      <>
                        <Col md={6} className="px-0 independent-scroller">
                          <HandbookContent {...handbook}/>
                          <PlayNav slug={handbook.slug}
                                   currentNav={contextData.currentNav}
                                   navigationQueue={navigationQueue}
                                   goToPreviousPage={goToPreviousPage}
                                   goToNextPage={goToNextPage} />
                        </Col>
                        <Col md={3} className="px-0 independent-scroller">
                          { searchTerm && <HandbookSearch {...handbook}/> }
                          { contextData.exportFlag && <HandbookExport {...handbook}/> }
                        </Col>
                      </>
                      :
                      <Col md={9} className="px-0 independent-scroller">
                        <HandbookContent {...handbook}/>
                        <div className="p-2">
                          <PlayNav slug={handbook.slug}
                                 currentNav={contextData.currentNav}
                                 navigationQueue={navigationQueue}
                                 goToPreviousPage={goToPreviousPage}
                                 goToNextPage={goToNextPage} />
                        </div>
                      </Col>
                  }
                </Row>
              </div>
            </SearchContext.Provider>
          </HandbookContext.Provider>
        )
      }}
    </Query>
  )
}
export default HandbookPlay;
