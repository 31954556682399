import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'

import '../styles/popover.scss'
import { Overlay, Popover } from 'react-bootstrap'

const PopoverViewer = (props) => { 
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const { popoverTitle, popoverContent, triggerContent, triggerEvent, triggerPosition } = props

  const handleEvent = (event, triggerType) => {
    if (triggerEvent === 'click' && triggerEvent === triggerType) {
      setShow(!show)
    } else if (triggerEvent === 'hover') {
      setShow(triggerType === 'mouse:over')
    }
    setTarget(event.target)
  }

  const transformNode = (node) => {
    if (node.name === 'a' && node.attribs) {
      if (node.attribs['data-type'] && node.attribs['data-type'] === 'page-link') {
        const pageId = node.attribs['data-page']
        return (
          <a
            href={`link-page-${pageId}`}
            key={`key-page-${pageId}`}
            onClick={(e) => {
              e.preventDefault()
              props.contentLinkHandler(pageId)
            }}
          >
            {node.children.map(child => convertNodeToElement(child))}
          </a>
        )
      }
      if (node.attribs.href && node.attribs.href.indexOf('slug::')) {
        const hrefElements = node.attribs.href.split('slug::')
        const pageSlug = hrefElements[hrefElements.length - 1]
        const { pageId } = props.findPageIdWithSlug(String(pageSlug).replace(/^\s+|\s+$/g, ''))
        if (pageId) {
          return (
            <a
              href={`link-page-${pageId}`}
              key={`key-page-${pageId}`}
              onClick={(e) => {
                e.preventDefault()
                props.contentLinkHandler(pageId)
              }}
            >
              {node.children.map(child => convertNodeToElement(child))}
            </a>
          )
        }
      }
    }
  }

  return (
    <>
      <span
        ref={ref}
        className='popover-trigger'
        onClick={(e) => handleEvent(e, 'click')}
        onMouseOut={(e) => handleEvent(e, 'mouse:out')}
        onMouseOver={(e) => handleEvent(e, 'mouse:over')}>
        {
          ReactHtmlParser(triggerContent, { transform: transformNode })
        }
      </span>
      <Overlay
        show={show}
        target={target}
        placement={triggerPosition}
        container={ref.current}
        popperConfig={{ strategy: 'fixed' }}
      >
        <Popover id={`handbook-popover-${triggerEvent || 'click'}-${triggerPosition || 'top'}`}>
          {
            popoverTitle &&
              <Popover.Title as='h3'>{popoverTitle}</Popover.Title>
          }
          <Popover.Content>
            {
              ReactHtmlParser(popoverContent, { transform: transformNode })
            }
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  )
}

PopoverViewer.propTypes = {
  popoverContent: PropTypes.string.isRequired,
  triggerContent: PropTypes.string.isRequired,
  triggerEvent: PropTypes.string,
  triggerPosition: PropTypes.string
}

export default PopoverViewer
