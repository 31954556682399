import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Handbooks from "./pages/Handbooks";
import Handbook from "./pages/Handbook";
import HandbookPlay from "./pages/HandbookPlay";
import Signup from "./components/Signup";
import Signin from "./components/Signin";
import NotFound from "./components/NotFound";
import ReactPiwik from 'react-piwik';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
 
const piwik = new ReactPiwik({
  url: 'https://stats.dial.community/',
  siteId: 10,
})
const trackAtConnect = true;

function App() {
  return (
    <BrowserRouter history={piwik.connectToHistory(history, trackAtConnect)}>
      <div id="app">
        <Routes>
          <Route path="/" exact element={<Handbooks/>} />
          <Route path="/resources/:slug" exact element={<Handbook/>} />
          <Route path="/resources/:slug/play" exact element={<HandbookPlay/>} />
          <Route path="/resources/:slug/play/:startingId" exact element={<HandbookPlay/>} />
          <Route path="/sign-up" element={<Signup/>} />
          <Route path="/sign-in" element={<Signin/>} />
          <Route path="/healthcheck" exact>{JSON.stringify(JSON.parse('{"webapp":true}'))}</Route>
          <Route element={<NotFound/>} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
