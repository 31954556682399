import React, { useContext } from "react";
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { HandbookContext, SearchContext } from "../HandbookContext";

const HANDBOOKS_QUERY = gql`
  query SearchHandbook($slug: String!, $search: String!) {
    searchHandbook(slug: $slug, search: $search) {
      id
      name
      slug
      snippet
      childPages { 
        id
        name
        slug
        snippet
        childPages { 
          id
          name
          slug
          snippet
        }
      }
    }
  }
`

const HandbookSearch = (props) => {
  const { slug } = props;
  const { contextData, setContextData } = useContext(HandbookContext);
  const { searchTerm } = useContext(SearchContext);
  return (
    <Query query={HANDBOOKS_QUERY}
      variables={{ slug: slug, search: searchTerm }}>
      {({ loading, error, data }) => {
        if (loading) return <div>Fetching search results ..</div>
        if (error) { console.log(error); return <div>Unable to retrieve search handbook!</div> }
        return (
          <div className="list-group mb-2">
            { data.searchHandbook.map((page) => {
              return (
                <button key={`list-item-${page.id}`} onClick={() => setContextData({ ...contextData, currentNav: page.id })}
                        className="list-group-item list-group-item-action flex-column align-items-start">
                  <div className="d-flex w-100 justify-content-between">
                    <div className="mb-1 font-weight-bold">{page.name}</div>
                  </div>
                  <p className="mb-1">{page.snippet}</p>
                </button>
              )
            })}
          </div>
        )
      }}
    </Query>
  );
}

export default HandbookSearch;
