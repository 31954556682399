// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-height {
  line-height: 1.2; }

.handbook-content {
  width: 100%; }

.independent-scroller {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll; }
`, "",{"version":3,"sources":["webpack://./src/styles/handbook.scss"],"names":[],"mappings":"AAAA;EACE,gBACF,EAAA;;AAEA;EACE,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB,EAAA","sourcesContent":[".line-height {\n  line-height: 1.2\n}\n\n.handbook-content {\n  width: 100%;\n}\n\n.independent-scroller {\n  height: 100vh;\n  overflow: hidden;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
