import React, { useContext } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { Card, Form } from 'react-bootstrap';
import { GoPlay } from "react-icons/go"
import { FormattedMessage, useIntl, defineMessage } from 'react-intl';

import { HandbookContext } from '../HandbookContext';
import ContentViewer from './ContentViewer'
import config from '../config';

const PAGE_CONTENT_QUERY = gql`
query GetPageContents($id: ID!, $locale: String) {
  pageContents(handbookPageId: $id, locale: $locale) {
    editorType
    html
    css
    locale
  }
}
`;

function getPage(handbook, currentNav){
  let handbookPage = [];
  if (handbook.handbookPages) {
    handbookPage = handbook.handbookPages.filter((page) => 
      page.id === currentNav || page.slug === currentNav
    );
  }
  if (handbookPage.length === 0) {
    handbook.handbookPages.map((page) => {
      if (page.childPages && handbookPage.length === 0) {
        handbookPage = page.childPages.filter((childPage) =>
        childPage.id === currentNav || childPage.slug === currentNav
        );
        if (page.childPages && handbookPage.length === 0) {
          page.childPages.map((childPage) => {
            if (childPage.childPages && handbookPage.length === 0) {
              handbookPage = childPage.childPages.filter((grandChildPage) => 
                grandChildPage.id === currentNav || grandChildPage.slug === currentNav
              )
            }
            return childPage
          });
        }
      }
      return page;
    }
  )}
  return handbookPage;
}

const pageHasQuestion = function(handbookPage) {
  let hasQuestion = false;
  if (handbookPage) {
    hasQuestion = handbookPage.handbookQuestion && handbookPage.handbookQuestion.questionText;
  }
  return hasQuestion;
}

const HandbookContent = (handbook) => {
  const intl = useIntl();
  
  let description = handbook.handbookDescriptions
                              .filter((d) => d.locale === intl.locale.substring(0, 2))
                              .shift();
  if (!description) {
    // Fallback to 'en' if the handbook doesn't have any non english data.
    description = handbook.handbookDescriptions
                          .filter((d) => d.locale === 'en')
                          .shift();
  }
  
  const { contextData, setContextData } = useContext(HandbookContext);

  const initialNav = function() {
    return contextData.currentNav === 0 || contextData.currentNav === undefined;
  }

  const questionAnswered = function() {
    return contextData.currentAnswer !== undefined;
  }

  const handleSelection = function(action) {
    if (action.indexOf("current_page") === -1) {
      setContextData({ ...contextData, currentNav: action.split("::")[1] });
    } else {
      setContextData({ ...contextData, currentAnswer: action });
    }
  }

  const resetSelectedAnswer = function() {
    setContextData({ ...contextData, currentAnswer: undefined });
  }

  const contentLinkHandler = function(pageId) {
    setContextData({
      ...contextData,
      childKey: undefined,
      parentKey: undefined, 
      currentAnswer: undefined,
      currentNav: pageId
    })
  }

  const findPageIdWithSlug = function(slug) {

    for (let page of handbook.handbookPages) {
      if (page.slug === slug || page.slug === slug.replace(/_/g, "-")) {
        return { pageId: page.id, pageName: page.name };
      }

      if (page.childPages) {
        for (let childPage of page.childPages) {
          if (childPage.slug === slug || childPage.slug === slug.replace(/_/g, "-")) {
            return { pageId: childPage.id, pageName: childPage.name };
          }

          if (childPage.childPages) {
            for (let grandChildPage of childPage.childPages) {
              if (grandChildPage.slug === slug || grandChildPage.slug === slug.replace(/_/g, "-"))  {
                return { pageId: grandChildPage.id, pageName: grandChildPage.name };
              }
            }
          }
        }
      }
    }

    return { pageId: undefined, pageName: undefined };
  }

  const switchMessage = defineMessage({
    id: "content.switch",
    defaultMessage: "Go back to question (fallback)."
  });

  const handbookPage = getPage(handbook, contextData.currentNav).shift();
  
  return (
    <Card className="handbook-content pl-0 border-bottom-0">
      {
        initialNav(contextData.currentNav) ?
          <>
            <Card.Body className="py-2 px-3">
              <div id={`${handbook.slug}-overview`} className="fr-view">
                { ReactHtmlParser(description.overview
                                             .replaceAll(config.handbook_image_url, 
                                                         config.handbooks_server + "/" + config.handbook_image_url),
                    { transform: (node) => {
                      if (node.name === "a" && node.attribs) {
                        if (node.attribs["data-type"] && node.attribs["data-type"] === "page-link") {
                          const pageId = node.attribs["data-page"];
                          return (
                            <a href={`link-page-${pageId}`}
                               key={`key-page-${pageId}`}
                               onClick={(e) => {                                                            
                                 e.preventDefault();                                                            
                                 contentLinkHandler(pageId);
                               }} >
                               { node.children.map(child => convertNodeToElement(child)) }
                            </a>
                          )
                        }
          
                        if (node.attribs["href"] && node.attribs["href"].indexOf("slug::")) {
                          const hrefElements = node.attribs["href"].split("slug::");
                          const pageSlug = hrefElements[hrefElements.length - 1];
                          const { pageId } = findPageIdWithSlug(String(pageSlug).replace(/^\s+|\s+$/g, ''));
                          if (pageId) {
                            return (
                              <a href={`link-page-${pageId}`}
                                 key={`key-page-${pageId}`}
                                 onClick={(e) => {                                                            
                                   e.preventDefault();                                                            
                                   contentLinkHandler(pageId);
                                 }} >
                                 { node.children.map(child => convertNodeToElement(child)) }
                              </a>
                            )
                          }
                        }
                      }
                    }
                  }
                )
                }
              </div>
            </Card.Body>
          </>
          : pageHasQuestion(handbookPage) && !questionAnswered(contextData.currentAnswer) ?
            <>
            <Card.Header className="py-2 px-3">
              <div id={`${handbookPage.slug}-overview`} className="h4 mb-0 float-left font-weight-bold text-blue">
                {handbookPage.name}
              </div>
              {
                !contextData.playing &&
                  <a href={`/resources/${handbook.slug}/play/${handbookPage.id}`}
                            className="float-right">
                    <GoPlay size={32} className="text-blue" />
                  </a>
              }
            </Card.Header>
            <Card.Body className="py-2 px-3">
              { handbookPage.handbookQuestion.questionText }
              <Form>
                { handbookPage.handbookQuestion.handbookAnswers.map((answer) => (
                  <div key={`key-${handbookPage.handbookQuestion.id}-${answer.id}`} className="form-check">
                    <Form.Check 
                      type='radio'
                      name={`id-${handbookPage.handbookQuestion.id}`}
                      label={`${answer.answerText}`}
                      value={`${answer.action}`}
                      checked={contextData.currentAnswer === answer.action}
                      onChange={() => handleSelection(answer.action)} 
                    />
                  </div>
                ))}
              </Form>
            </Card.Body>
          </>
          :
            <Query query={ PAGE_CONTENT_QUERY }
                   variables={{ id: handbookPage.id, locale: intl.locale }}>
              {({ loading, error, data }) => {
                if (loading) return <div><FormattedMessage id="content.fetching" /></div>
                if (error) { console.log(error); return <div><FormattedMessage id="content.no-data" /></div> }
                const pageContents = data.pageContents
                return (
                  <>
                    <Card.Header className="py-2 px-3">
                      <div id={`${handbookPage.slug}-overview`} className="h4 mb-0 float-left font-weight-bold text-blue">
                        {handbookPage.name}
                      </div>
                      {
                        !contextData.playing &&
                          <a href={`/resources/${handbook.slug}/play/${handbookPage.id}`}
                                    className="float-right">
                            <GoPlay size={32} className="text-blue" />
                          </a>
                      }
                    </Card.Header>
                    <Card.Body className="py-2 px-3">
                      <div id={`${handbookPage.slug}-description`}>
                        { pageContents && 
                          <ContentViewer findPageIdWithSlug={findPageIdWithSlug}
                                         contentLinkHandler={contentLinkHandler}
                                         pageContents={pageContents} />
                        }
                      </div>
                      { pageHasQuestion(handbookPage) &&
                        <Form>
                          <Form.Check 
                            type="switch"
                            id="question-switch"
                            label={intl.formatMessage(switchMessage)}
                            onChange={() => resetSelectedAnswer()}
                          />
                        </Form>
                      }
                    </Card.Body>
                  </>
                )
              }}
            </Query>
      }
    </Card>
  )
}

export default HandbookContent;
