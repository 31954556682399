// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
  background-color: #3E9EDD; }

.logo {
  height: 86px;
  width: 300px; }

.nav-link {
  color: white !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.nav-link:hover {
  /* color: #bbbbbb !important; */
  color: #fbab18 !important; }

.topnav-handbook {
  cursor: pointer; }

.inline {
  display: inline; }
`, "",{"version":3,"sources":["webpack://./src/styles/header.scss"],"names":[],"mappings":"AAAA;EACE,yBACF,EAAA;;AAEA;EACE,YAAY;EACZ,YAAY,EAAA;;AAGd;EACE,uBAAuB;EACvB,yBAAyB;EACzB,4BAA4B,EAAA;;AAI9B;EACI,+BAAA;EACA,yBAAyB,EAAA;;AAG7B;EACE,eAAe,EAAA;;AAGjB;EACE,eAAe,EAAA","sourcesContent":["#header {\n  background-color: #3E9EDD\n}\n\n.logo {\n  height: 86px;\n  width: 300px;\n}\n\n.nav-link {\n  color: white !important;\n  padding-top: 0 !important;\n  padding-bottom: 0 !important;\n}\n\n\n.nav-link:hover {\n    /* color: #bbbbbb !important; */\n    color: #fbab18 !important;\n}\n\n.topnav-handbook {\n  cursor: pointer;\n}\n\n.inline {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
