import React from "react";
import { FaShareAlt } from 'react-icons/fa'
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom'

import '../styles/footer.scss';

const Footer = () => {
  let location = useLocation();
  if (location.pathname === '/healthcheck') 
    return null
    
  return (
  <footer id="footer" className="mt-2">
    <ul className="icons sponsors">
      <li className="description">
        <a href="https://digitalimpactalliance.org">
            <FormattedMessage id="footer.dial" />
        </a>
        <FormattedMessage id="footer.dial-message" />
      </li>
      <li><img src="/images/unf-logo.png" alt="United Nations Foundation" /></li>
      <li><img src="/images/bill-n-melinda-logo.png" alt="Bill and Melinda Gates Foundation" /></li>
      <li><img src="/images/sweden-logo.png" alt="Swedish International Development Cooperation Agency" /></li>
      <li><img src="/images/dfid-logo.png" alt="Department for International Development" height="94" /></li>
    </ul>
    <ul className="copyright">
      <li>
        <FaShareAlt />
        <a target="_blank" rel="noreferrer noopener" href="https://gitlab.com/dial/osc/eng/t4d-online-catalog/playbooks"
          className="text-decor ml-1">
          <FormattedMessage id="footer.gitlab" />
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li><FormattedMessage id="footer.social-media" /></li>
      <li>
        <a href="https://twitter.com/DIAL_Community" rel="noopener noreferrer" target="_blank">
          <FaTwitter />
        </a>
      </li>
      <li>
        <a href="https://facebook.com/DigitalImpactAlliance" rel="noopener noreferrer" target="_blank">
          <FaFacebook />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/dial_community" rel="noopener noreferrer" target="_blank">
          <FaInstagram />
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>
        <FormattedMessage id="footer.icons" />
      </li>
      <li>
        <a target="_blank" rel="noreferrer noopener" href="https://fontawesome.com/">
          <FormattedMessage id="footer.font-awesome" />
        </a>
      </li>
      <li>
        <a target="_blank" rel="noreferrer noopener" href="https://www.globalgoals.org/">
          <FormattedMessage id="footer.font-sdg" />
        </a>
      </li>
    </ul>
  </footer>
)};

export default Footer;
