import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Row, Col, Button } from 'react-bootstrap'


const PlayNav = (props) => {
  const { slug, currentNav, navigationQueue, goToPreviousPage, goToNextPage } = props;
  return (
    <Row className="float-right mb-2">
      <Col>
        <Button variant="outline-primary" href={`/resources/${slug}`} className="mr-2" >
          <FormattedMessage id="navigation.return" />
        </Button>
        <Button disabled={ currentNav === undefined } className="mr-2"
                variant={ currentNav === undefined ? 'outline-secondary' : 'outline-primary' }
                onClick={() => goToPreviousPage()} >
          <FormattedMessage id="navigation.previous" />
        </Button>
        <Button disabled={ navigationQueue.indexOf(currentNav) === navigationQueue.length - 1 }
                variant={ navigationQueue.indexOf(currentNav) === navigationQueue.length - 1 ? 'outline-secondary' : 'primary' }
                onClick={() => goToNextPage()} >
          <FormattedMessage id="navigation.next" />
        </Button>
      </Col>
    </Row>
  )
}

export default PlayNav
