import React, { useContext } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionButton} from 'react-bootstrap/AccordionButton';

import { FaBook, FaFile, FaAngleDown, FaAngleUp } from "react-icons/fa";

import '../styles/handbooknav.scss';
import { HandbookContext } from '../HandbookContext';

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div onClick={decoratedOnClick}>
      { children }
      <div className="sidebar-button">
        {
          isCurrentEventKey ? <FaAngleUp /> : <FaAngleDown />
        }
      </div>
    </div>
  );
}

const HandbookNav = (handbook) => {
  const { contextData, setContextData } = useContext(HandbookContext);

  const currentNavIs = function(expectedPage) {
    return contextData.currentNav === expectedPage.id || contextData.currentNav === expectedPage.slug;
  }

  const handleClick = function(page) {
    setContextData({
      ...contextData,
      childKey: undefined,
      parentKey: undefined, 
      currentAnswer: undefined,
      currentNav: page.id
    })
  }
  
  return (
      <Accordion activeKey={contextData.parentKey} className="px-0 h-100">
        { 
          handbook.handbookPages.length > 0 &&
          <>
            {   
              handbook.handbookPages.map((page) => {
                return (
                  page.childPages ? 
                    <Card key={page.id}>
                      <Card.Header>
                        <ContextAwareToggle eventKey={page.id}>
                          <div className={`sidebar-menuitem ${currentNavIs(page) && 'selected'}`}
                               onClick={() => handleClick(page)}>
                            <FaBook className="mr-2" />{page.name}
                          </div>
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={page.id}>
                        <Card.Body className="sidebar-collapse">
                        { 
                          page.childPages.map((childPage) => {
                            return (
                              childPage.childPages ?
                                <Card key={childPage.id}>
                                  <Accordion activeKey={contextData.childKey}>
                                    <Card.Header className="child-card">
                                      <ContextAwareToggle eventKey={childPage.id}>
                                        <div className={`sidebar-menuitem ${currentNavIs(childPage) && 'selected'}`}
                                             onClick={() => handleClick(childPage)}>
                                          <FaBook className="mr-2" />{childPage.name}
                                        </div>
                                      </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={childPage.id}>
                                      <Card.Body className="sidebar-collapse ml-3">
                                        { childPage.childPages.map((grandchildPage) => {
                                          return <Card.Header key={grandchildPage.id} className="child-card">
                                            <div className={`sidebar-menuitem ${currentNavIs(grandchildPage) && 'selected'}`}
                                                 onClick={() => handleClick(grandchildPage)}>
                                              <FaFile className="mr-2" />{grandchildPage.name}
                                            </div>
                                            </Card.Header>
                                          }
                                        )}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Accordion>
                                </Card>
                              :
                                <Card key={childPage.id}>
                                  <Card.Header className="child-card">
                                    <div className={`sidebar-menuitem ${currentNavIs(childPage) && 'selected'}`}
                                         onClick={() => handleClick(childPage)}>
                                      <FaFile className="mr-2" />{childPage.name}
                                    </div>
                                  </Card.Header>
                                </Card>
                            )
                          })
                        }
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  :
                    <Card key={page.id}>
                      <Card.Header>
                        <div className={`sidebar-menuitem ${currentNavIs(page) && 'selected'}`}
                             onClick={() => handleClick(page)}>
                          <FaFile className="mr-2" />{page.name}
                        </div>
                      </Card.Header>
                    </Card>
                )
              })
            }
          </>   
        } 
      </Accordion>
  )
}

export default HandbookNav;
