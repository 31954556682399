import React, { useContext, useState } from 'react';
import { Card, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl, defineMessage } from 'react-intl'

import { saveAs } from 'file-saver';

import { useLazyQuery } from 'react-apollo';
import gql from 'graphql-tag';

import '../styles/handbooknav.scss';
import { HandbookContext } from '../HandbookContext';

const EXPORT_QUERY = gql`
  query ExportPageContent($pageIds: [Int!]!) {
    exportPageContent(pageIds: $pageIds) {
      data
      filename
      locale
    }
  }
`;

function b64toBlob(b64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 512;
  b64Data = b64Data.replace(/^[^,]+,/, '');
  b64Data = b64Data.replace(/\s/g, '');
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const HandbookExport = (handbook) => {
  const intl = useIntl();
  const [checkedItems, setCheckedItems] = useState({});
  const { contextData, setContextData } = useContext(HandbookContext);

  // Message for the select all option
  const exportAll = defineMessage({
    id: "export.all",
    defaultMessage: "Select all"
  });

  const checkboxes = [];
  handbook.handbookPages.map((page) => {
    checkboxes.push({
      name: page.id,
      label: page.name,
      offset: 0
    });
  
    if (page.childPages) {
      page.childPages.map(childPage => {
        checkboxes.push({
          name: childPage.id,
          label: childPage.name,
          offset: 2
        });

        if (childPage.childPages) {
          childPage.childPages.map  (grandChildPage => {
            checkboxes.push({
              name: grandChildPage.id,
              label: grandChildPage.name,
              offset: 4
            });
            return grandChildPage;
          });
        }

        return childPage;
      });
    }

    return page;
  });

  const handleChange = (event) => {
    if (event.target.id === 'export-all') {
      const selectedItems = { ...checkedItems };
      checkboxes.map(function(item) {
        return selectedItems[item.name] = event.target.checked;
      });
      setCheckedItems({ ...selectedItems });
    } else {
      setCheckedItems({ ...checkedItems, [event.target.id] : event.target.checked });
    }
  }

  const [executeExport, { loading }] = useLazyQuery(EXPORT_QUERY, {
    variables: { "pageIds": Object.keys(checkedItems).filter(key => checkedItems[key]).map(key => parseInt(key))},
    onCompleted: (data) => {
      const output = data.exportPageContent;
      saveAs(b64toBlob(output.data, "application/pdf"), output.filename);
      setContextData({ ...contextData, exportFlag: !contextData.exportFlag });
    }
  });

  return (
    <Card className="handbook-content">
      <Card.Header className="h4 text-blue mb-0 py-2 px-3">
        <FormattedMessage id="export.header" />{` ${handbook.name}`}
      </Card.Header>
      <Card.Body className="mb-2 mr-2">
        
        <Form>
          { loading ?
          <> 
          <Spinner animation="border" role="status" />
          <div className="pl-3 mb-4 text-muted inline"><FormattedMessage id="export.exporting" /></div>
          </> 
          :
          <>
          <Form.Check type="checkbox"
                      key="export-all"
                      id="export-all"
                      label={intl.formatMessage(exportAll)}
                      onChange={handleChange} 
          />
          <hr className="my-1" />
          { 
            checkboxes.map((checkbox) => {
              return (
                <>
                <Form.Check type="checkbox"
                            key={`export-${checkbox.name}`}
                            id={checkbox.name}
                            label={checkbox.label}
                            checked={checkedItems[checkbox.name] || false}
                            onChange={handleChange}
                            className={`ml-${checkbox.offset}`}
                />
                </>
              )
            })
          }
          </>
          }
          <Row className="float-right mt-2 mb-n3 mr-n3">
            <Col className="px-0">
              <Button variant='primary'
                      disabled={loading}
                      onClick={() => { executeExport() }} >
                <FormattedMessage id="export.execute" />
              </Button>
            </Col>
          </Row>
        </Form>

      </Card.Body>
    </Card>
  )
}

export default HandbookExport;
