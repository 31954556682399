import React from "react";
import { renderToString } from 'react-dom/server'
import PropTypes from "prop-types";
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

import config from '../config';

import '../styles/viewcontent.scss';
import RevealViewer from "./RevealViewer";
import PopoverViewer from "./PopoverViewer";

const ContentViewer = (props) => {
  let pageHtml = props.pageContents.html
                      .replaceAll(config.handbook_image_url, config.handbooks_server + "/" + config.handbook_image_url);
  
  if (pageHtml.indexOf("_{{") >= 0 && pageHtml.indexOf("}}_") >= 0) {
    const slugFragments = props.pageContents.html
                               .split("_{{")
                               .map(data => data.split("}}_"))
                               .filter(e => e.length > 1)
                               .map(e => e[0])
                               .filter(e => e);
    for (let slugFragment of slugFragments) {
      const { pageId, pageName } = props.findPageIdWithSlug(String(slugFragment).replace(/^\s+|\s+$/g, ''));
      if (pageId && pageName) {
        pageHtml = pageHtml.replaceAll(`_{{${slugFragment}}}_`,
                                       `<a data-type="page-link" data-page="${pageId}">${pageName}</a>`);
      } else {
        pageHtml = pageHtml.replaceAll(`_{{`, '').replaceAll(`}}_`, '');
      }
    }
  }

  const pageCss = props.pageContents.css
                       .replaceAll(config.handbook_image_url, config.handbooks_server + "/" + config.handbook_image_url);
  return (
  <>
    <style>
      { pageCss }
    </style>
    <div className="fr-view">
      {
        ReactHtmlParser(pageHtml, {
          transform: (node) => {
            if (node.name === 'div' && node.attribs && node.attribs['data-component-marker'] &&
              node.attribs['data-component-marker'] === 'handbook-reveal') {
              return (
                <RevealViewer
                  key='reveal'
                  contentLinkHandler={props.contentLinkHandler}
                  revealContent={renderToString(convertNodeToElement(node.children[0]))}
                  triggerContent={renderToString(convertNodeToElement(node.children[1]))}
                  triggerEvent={node.attribs['data-trigger']}
                />
              )
            } else if (node.name === 'span' && node.attribs && node.attribs['data-component-marker'] &&
              node.attribs['data-component-marker'] === 'handbook-popover') {
              return (
                <PopoverViewer
                  key='popover'
                  popoverTitle={node.attribs['data-title']}
                  contentLinkHandler={props.contentLinkHandler}
                  popoverContent={renderToString(convertNodeToElement(node.children[0]))}
                  triggerContent={renderToString(convertNodeToElement(node.children[1]))}
                  triggerEvent={node.attribs['data-trigger']}
                  triggerPosition={node.attribs['data-placement']}
                />
              )
            } else if (node.name === "a" && node.attribs) {
              if (node.attribs["data-type"] && node.attribs["data-type"] === "page-link") {
                const pageId = node.attribs["data-page"];
                return (
                  <a href={`link-page-${pageId}`}
                     key={`key-page-${pageId}`}
                     onClick={(e) => {                                                            
                       e.preventDefault();                                                            
                       props.contentLinkHandler(pageId);
                     }} >
                     { node.children.map(child => convertNodeToElement(child)) }
                  </a>
                )
              }

              if (node.attribs["href"] && node.attribs["href"].indexOf("slug::")) {
                const hrefElements = node.attribs["href"].split("slug::");
                const pageSlug = hrefElements[hrefElements.length - 1];
                const { pageId } = props.findPageIdWithSlug(String(pageSlug).replace(/^\s+|\s+$/g, ''));
                if (pageId) {
                  return (
                    <a href={`link-page-${pageId}`}
                       key={`key-page-${pageId}`}
                       onClick={(e) => {                                                            
                         e.preventDefault();                                                            
                         props.contentLinkHandler(pageId);
                       }} >
                       { node.children.map(child => convertNodeToElement(child)) }
                    </a>
                  )
                }
              }
            }
          } 
        })
      }
    </div>
  </>
  )
}

ContentViewer.propTypes = {
  pageContents: PropTypes.object.isRequired
}

export default ContentViewer
