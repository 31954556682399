import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import HandbookCard from '../components/HandbookCard'
import Header from "../components/Header";

const HANDBOOKS_QUERY = gql`
query {
  handbooks {
    id
    name
    slug
    phases
    maturity
    imageFile
    handbookDescriptions {
      cover
      overview
    }
  }
}
`;

const Handbooks = () => {
  return (
    <>
      <Header />
      <div id="content" className="container-fluid with-header">
        <Query query={HANDBOOKS_QUERY}>
          {({ loading, error, data }) => {
            if (loading) return <div>Fetching..</div>
            if (error) { console.log(error); return <div>Error!</div> }
            return (
              <div className="row flex flex-wrap">
                {data.handbooks.map((handbook) => {
                  return <HandbookCard key={handbook.id} handbook={handbook} />
                })}
              </div>
            )
          }}
        </Query>
      </div>
    </>
  )
}

export default Handbooks;
