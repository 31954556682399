import React from "react";
import { IntlProvider } from "react-intl";
import App from './App';

import messages from './translations/messages';

const msg = {
  'en-US': 'en',
  'fr-FR': 'fr',
  'de-DE': 'de'
};

const IntlContext = React.createContext();

class I18nProvider extends React.Component {
  constructor(...args) {
    super(...args);

    this.switchLanguage = (languageLocale) => {
      this.setState({ locale: languageLocale });
      return false;
    }

    this.state = {
      locale: 'en-US',
      switchLanguage: this.switchLanguage
    };
  }


  render() {
    const { locale } = this.state;
    return (
      <IntlContext.Provider value={this.state}>
        <IntlProvider key={locale} locale={locale} messages={messages[msg[locale]]} defaultLocale="en-US">
          <App />
        </IntlProvider>
      </IntlContext.Provider>
    );
  }
}

export { I18nProvider, IntlContext };
