import React from "react";
import CurrentUser from "../components/CurrentUser";

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';

import { FormattedMessage } from 'react-intl';

import '../styles/header.scss';
import { FaExternalLinkAlt } from "react-icons/fa";

const Header = () => (
  <CurrentUser>
    {currentUser => (
      <header className="header-outer header-widget">
        <Navbar id="header" className="navbar-expand-lg navbar-dark fixed-top">
          <Navbar.Brand href="/">
            <img src="/logo-dial.png" alt="DIAL logo" height="50px" className="mr-2" />
            <FormattedMessage id="header.resources-portal" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="https://solutions.dial.community" target="_blank">
                <FormattedMessage id="header.product-catalog" />
                <FaExternalLinkAlt className="ml-2" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    )}
  </CurrentUser>
);

export default Header;
