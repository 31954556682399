import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import withRouter from "../lib/withRouter";
import Error from "./Error";
import Loading from "./Loading";
import { GET_CURRENT_USER_QUERY } from "./CurrentUser";

const SIGNIN_MUTATION = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(input: {email: $email, password: $password}) {
      token
      user {
        email
      }
    }
  }
`;

class Signin extends Component {
  state = {
    email: "",
    password: ""
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  isFormValid = event => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  handleCompleted = data => {
    console.log(data)
    localStorage.setItem("auth-token", data.signIn.token);

    this.props.history.goBack();
  };

  handleUpdate = (cache, { data }) => {
    cache.writeQuery({
      query: GET_CURRENT_USER_QUERY,
      data: { me: data.signIn.user }
    });
  };

  render() {
    return (
      <Mutation
        mutation={SIGNIN_MUTATION}
        variables={this.state}
        onCompleted={this.handleCompleted}
        update={this.handleUpdate}>
        {(signin, { error, loading }) => {
          if (loading) return <Loading />;
          return (
            <form
              className="signin"
              onSubmit={e => {
                e.preventDefault();
                signin();
              }}>
              <h2>Sign In</h2>
              <h3>
                <Link to="/sign-up">Need an account?</Link>
              </h3>
              <Error error={error} />
              <fieldset>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  autoFocus
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  required
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <button type="submit" disabled={!this.isFormValid()}>
                  Sign In
                </button>
              </fieldset>
            </form>
          );
        }}
      </Mutation>
    );
  }
}

export default withRouter(Signin);