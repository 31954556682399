// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  background-color: #FBAB18; }

.accordion .card {
  background-color: #FBAB18;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5); }

.accordion .child-card {
  background-color: #ffeecf; }

.accordion .card .selected {
  font-weight: bold; }

.sidebar-header {
  margin-left: 15px;
  background-color: #343a40;
  color: #adadad; }

.sidebar-button {
  border: none;
  float: right; }

.sidebar-collapse {
  padding: 0px 0px 0px 5px !important; }

.sidebar-menuitem {
  display: inline;
  cursor: pointer; }

#content .row {
  padding: 0px; }

#content .navbar-brand {
  min-height: 0px;
  min-width: 0px; }
`, "",{"version":3,"sources":["webpack://./src/styles/handbooknav.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAA;;AAG3B;EACE,yBAAyB;EACzB,yBAAyB;EACzB,iDAAiD;EACjD,8CAA8C,EAAA;;AAGhD;EACE,yBAAyB,EAAA;;AAG3B;EACE,iBAAiB,EAAA;;AAGnB;EACE,iBAAiB;EACjB,yBAAyB;EACzB,cAAc,EAAA;;AAGhB;EACE,YAAY;EACZ,YAAY,EAAA;;AAGd;EACE,mCAAmC,EAAA;;AAGrC;EACE,eAAe;EACf,eAAe,EAAA;;AAGjB;EACE,YAAY,EAAA;;AAGd;EACE,eAAe;EACf,cAAc,EAAA","sourcesContent":[".accordion {\n  background-color: #FBAB18;  // #144A6C\n}\n\n.accordion .card {\n  background-color: #FBAB18; // #144A6C \n  color: rgba(0, 0, 0, 0.9);\n  border-bottom: 1px solid rgba(255, 255, 255, 0.5);\n  border-top: 1px solid rgba(255, 255, 255, 0.5);\n}\n\n.accordion .child-card {\n  background-color: #ffeecf;\n}\n\n.accordion .card .selected {\n  font-weight: bold;\n}\n\n.sidebar-header {\n  margin-left: 15px;\n  background-color: #343a40;\n  color: #adadad;\n}\n\n.sidebar-button {\n  border: none;\n  float: right;\n}\n\n.sidebar-collapse {\n  padding: 0px 0px 0px 5px !important;\n}\n\n.sidebar-menuitem {\n  display: inline;\n  cursor: pointer;\n}\n\n#content .row {\n  padding: 0px;\n}\n\n#content .navbar-brand { \n  min-height: 0px;\n  min-width: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
