import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Error from "./Error";

const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    me {
      email
    }
  }
`;
const CurrentUser = (props) => {
  return (
    <Query query={GET_CURRENT_USER_QUERY}>
      {({ data, error }) => {
        if (error) return <Error error={error} />;
        return data ? props.children(data.me) : null;
      }}
    </Query>
  );
}

CurrentUser.propTypes = {
  children: PropTypes.func.isRequired
};

export default CurrentUser;
export { GET_CURRENT_USER_QUERY };
