import React, { useContext } from 'react'
import { Nav, Navbar } from 'react-bootstrap'

import { FaFilePdf, FaHome, FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa'

import { EmailShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'

import { AiOutlineMail } from 'react-icons/ai'
import { IconContext } from 'react-icons'

import '../styles/header.scss'

import { HandbookContext } from '../HandbookContext'
// import LanguageSwitcher from './LanguageSwitcher'
import TopSearch from './TopSearch'
import config from '../config'

const TopNav = (handbook) => {
  const { contextData, setContextData } = useContext(HandbookContext)

  return (
    <Navbar id='header' expand='lg' className='navbar-expand-lg navbar-dark'>
      <Navbar.Brand>
        <img src='/logo-dial.png' alt='DIAL logo' height='50px' className='mr-2' />
        <img src={config.handbooks_server + handbook.imageFile} alt={handbook.name} height='50px' className='mr-5' />
        <div
          className='topnav-handbook inline font-weight-bold'
          onClick={() => setContextData({ ...contextData, currentNav: undefined })}
        >
          {handbook.name}
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='ml-auto pt-3'>
          <Nav.Link href='/'>
            <FaHome size={32} className='text-white mt-1' />
          </Nav.Link>
          <TopSearch />
          <Nav.Link className='text-white mt-1' href={`${handbook.pdfUrl}`} target='_blank'>
            {/* onClick={() => setContextData({ ...contextData, exportFlag: !contextData.exportFlag })} */}
            <FaFilePdf size={32} className='text-white' />
          </Nav.Link>
          <div className='float-right pl-5'>
            <IconContext.Provider value={{ color: 'white', size: '28', padding: '2' }}>
              <TwitterShareButton url={config.handbooks_server + `/resources/${handbook.slug}`}>
                <FaTwitter className='m-2' />
              </TwitterShareButton>
              <FacebookShareButton url={config.handbooks_server + `/resources/${handbook.slug}`}>
                <FaFacebook className='m-2' />
              </FacebookShareButton>
              <LinkedinShareButton url={config.handbooks_server + `/resources/${handbook.slug}`}>
                <FaLinkedin className='m-2' />
              </LinkedinShareButton>
              <EmailShareButton url={config.handbooks_server + `/resources/${handbook.slug}`}>
                <AiOutlineMail className='m-2' />
              </EmailShareButton>
            </IconContext.Provider>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default TopNav
