// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reveal-enter {
  opacity: 0; }

.reveal-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.reveal-exit {
  opacity: 1; }

.reveal-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in; }
`, "",{"version":3,"sources":["webpack://./src/styles/reveal.scss"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAA;;AAGZ;EACE,UAAU;EACV,iCAAiC,EAAA;;AAGnC;EACE,UAAU,EAAA;;AAGZ;EACE,UAAU;EACV,iCAAiC,EAAA","sourcesContent":[".reveal-enter {\n  opacity: 0;\n}\n\n.reveal-enter-active {\n  opacity: 1;\n  transition: opacity 500ms ease-in;\n}\n\n.reveal-exit {\n  opacity: 1;\n}\n\n.reveal-exit-active {\n  opacity: 0;\n  transition: opacity 500ms ease-in;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
