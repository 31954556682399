import React, { useContext } from "react";
import { FormattedMessage } from 'react-intl';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import { SearchContext } from "../HandbookContext";

const TopSearch = () => {
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  return (
    <Form>
      <InputGroup className="mb-3 mr-2">
        <FormattedMessage id="nav.search">
          {placeholder =>
            <FormControl type="text" placeholder={placeholder} value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          }
        </FormattedMessage>
        <Button><FaSearch /></Button>
      </InputGroup>
    </Form>
  );
}

export default TopSearch
